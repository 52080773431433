import React, { useState } from 'react';
import { Calendar, Droplet, Shirt, MapPin, Award, Camera, Bath, Coffee, Wifi, Heart, Sun, Bell, Utensils, Wine, ExternalLink, X, Star, ChevronLeft, ChevronRight, MessageSquare, Send, Instagram, Facebook, Twitter, Youtube } from 'lucide-react';
import { Plus, Minus } from 'lucide-react';

const HotelLinkInBio = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [showGallery, setShowGallery] = useState(false);
  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null);
  const [currentReview, setCurrentReview] = useState(0);
  const [currentRoom, setCurrentRoom] = useState(0);
  const [currentAttraction, setCurrentAttraction] = useState(0);

  const navigateGallery = (direction) => {
    setSelectedGalleryImage((prev) => {
      if (direction === 'next') {
        return prev === images.gallery.length - 1 ? 0 : prev + 1;
      } else {
        return prev === 0 ? images.gallery.length - 1 : prev - 1;
      }
    });
  };

  const [openFaq, setOpenFaq] = useState(null);

  const faqs = [
    {
      question: "When is check out?",
      answer: "Check out is at 11:00am."
    },
    {
      question: "Do your cabins have Wifi?",
      answer: "Each cabin has complimentary WiFi."
    },
    {
      question: "Do your cabins allow pets?",
      answer: "Yes! Our cabins are pet friendly."
    }
  ];
  // Image paths and data
  const images = {
    logo: "/images/LiveOakLakeProfilePic.jpg",
    heroVideo: "/videos/liveoaklakevideo.mp4",
    lakesidesouth: "/images/lakesidesouth.webp",
    forestedge: "/images/forestedge.webp",
    map: "/images/dean-map.png",
    experiencethearea1: "/images/experiencethearea1.webp",
    experiencethearea2: "/images/experiencethearea2.webp",
    experiencethearea3: "/images/experiencethearea3.webp",
    gallery: [
      "/images/liveoaklakegallery1.webp",
      "/images/liveoaklakegallery2.webp",
      "/images/liveoaklakegallery3.webp",
      "/images/liveoaklakegallery4.webp",
      "/images/liveoaklakegallery5.webp",
      "/images/liveoaklakegallery6.webp",
      "/images/liveoaklakegallery7.webp",
      "/images/liveoaklakegallery8.webp",
      "/images/liveoaklakegallery9.webp"
    ]
  };

  const reviews = [
    {
      id: 1,
      source: 'Google',
      rating: 5,
      text: "We recently celebrated our anniversary at Live Oak Lake cabins, and it was the perfect getaway! The cabin was immaculate and beautifully appointed, making us feel right at home. Our host provided top-tier customer service, ensuring every detail was taken care of. The serene surroundings and cozy atmosphere made our stay unforgettable. We can’t recommend Live Oak Lake enough for a peaceful retreat!",
      author: "Rico Willis",
      date: "November 2024"
    },
    {
      id: 2,
      source: 'Yelp',
      rating: 5,
      text: "We had a wonderful experience staying here. Our cabin had a little creek with a stream of water flowing and the sound of nature was beautiful. The cabin had everything you need for your stay. We didn't jump in the hot tub because of spiders around there, probably because of all the rain. The cabin had games to play as well. I would definitely go again but when the weather is nice. Just a tip I would say they should recommend spraying for spiders, wasp or any sort of bugs periodically because there were lots of course it being in a wooded area away from everything. Sadly I did not take pictures.",
      author: "Monica C.",
      date: "June 2024"
    },
    {
      id: 3,
      source: 'Google',
      rating: 5,
      text: "Spent our 23rd wedding anniversary at this beautiful location and was a perfect getaway for us. Spent some time in the pool relaxing made some smores over the fire pit and the atmosphere was awesome. Definitely will return….thanks James for a wonderful stay!",
      author: "Craig Crowe",
      date: "October 2024"
    },
    {
      id: 4,
      source: 'Yelp',
      rating: 5,
      text: "Unbelievable retreat just outside Waco. I grew up nearby and moved away 10+ years ago and I am flabbergasted by all that has changed in the interim, not least Live Oak Lake. We (family with small children) stayed there for a week and couldn't have hoped for anything more (besides a longer stay). We will be back!",
      author: "Gabe F.",
      date: "June 2024"
    }
  ];

  const popularRooms = [
    {
      name: "Lakeside South",
      price: "$180/Night",
      description: "Sleeps 2 Pet Friendly ($95 nonrefundable fee) Hot Tub Included",
      image: images.lakesidesouth
    },
    {
      name: "Forest Edge",
      price: "$180/Night",
      description: "Sleeps 4 Pet Friendly ($95 nonrefundable fee) Hot Tub Included",
      image: images.forestedge
    }
  ];

  const attractions = [
    {
      name: "Homestead Village",
      type: "Experience & Shopping",
      description: "Homestead Craft Village offers shopping, dining, and self-guided tours.",
      image: images.experiencethearea1
    },
    {
      name: "Magnolia Market",
      type: "History & Games",
      description: "Magnolia Market and its famous landmark silos occupy two city blocks in downtown Waco.",
      image: images.experiencethearea2
    },
    {
      name: "Wace Mammoth Monument",
      type: "History",
      description: "Standing as tall as 14 feet and weighing 20,000 pounds, Columbian mammoths roamed across what is present-day Texas thousands of years ago.",
      image: images.experiencethearea3
    }
  ];

  const amenities = [
    { icon: <Utensils className="w-6 h-6" />, label: "Kitchen" },
    { icon: <Shirt className="w-6 h-6" />, label: "Laundry" },
    { icon: <Wifi className="w-6 h-6" />, label: "WiFi" },
    { icon: <Coffee className="w-6 h-6" />, label: "Coffee Machine" },
    { icon: <Bath className="w-6 h-6" />, label: "Bath" },
    { icon: <Droplet className="w-6 h-6" />, label: "Hot Tub" },
  ];

  // Helper Functions
  const nextReview = () => {
    setCurrentReview((prev) => (prev === reviews.length - 1 ? 0 : prev + 1));
  };

  const prevReview = () => {
    setCurrentReview((prev) => (prev === 0 ? reviews.length - 1 : prev - 1));
  };

  const nextRoom = () => {
    setCurrentRoom((prev) => (prev === popularRooms.length - 1 ? 0 : prev + 1));
  };

  const prevRoom = () => {
    setCurrentRoom((prev) => (prev === 0 ? popularRooms.length - 1 : prev - 1));
  };

  const nextAttraction = () => {
    setCurrentAttraction((prev) => (prev === attractions.length - 1 ? 0 : prev + 1));
  };

  const prevAttraction = () => {
    setCurrentAttraction((prev) => (prev === 0 ? attractions.length - 1 : prev - 1));
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        className={`w-4 h-4 ${index < rating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'}`}
      />
    ));
  };

  const socialLinks = [
    {
      icon: <Instagram className="w-6 h-6" />,
      label: "Instagram",
      url: "https://www.instagram.com/liveoaklake/"
    },
    {
      icon: <Facebook className="w-6 h-6" />,
      label: "Facebook",
      url: "https://www.facebook.com/Liveoaklake/"
    },
    {
      icon: <Youtube className="w-6 h-6" />,
      label: "Youtube",
      url: "https://www.youtube.com/channel/UCggd6TAjfoEL7oFZ7gAElVw"
    },
    {
      icon: <Twitter className="w-6 h-6" />,
      label: "Twitter",
      url: "https://twitter.com/liveoaklake"
    }
  ];
  
  return (
    <div className="min-h-screen bg-white flex justify-center">
      <div className="bg-white w-full max-w-md mx-auto overflow-hidden pb-24 min-h-screen relative">
        
        {/* Profile Header */}
        <div className="bg-white pb-6 mb-4 shadow-sm mx-4">
          <div className="relative h-96">
            <div className="rounded-xl overflow-hidden h-full">
              <video 
                autoPlay 
                muted 
                loop 
                playsInline
                className="w-full h-full object-cover"
                poster={images.logo}
              >
                <source src={images.heroVideo} type="video/mp4" />
                <img 
                  src={images.logo}
                  alt="Live Oak Lake" 
                  className="w-full h-full object-cover"
                />
              </video>
            </div>
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 z-10">
              <div className="rounded-full border-4 border-white overflow-hidden w-24 h-24 shadow-lg bg-white">
                <img 
                  src={images.logo}
                  alt="Live Oak Lake" 
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center mt-14">
            <h1 className="text-2xl font-bold text-gray-900">Live Oak Lake</h1>
            <div className="flex items-center text-gray-600 mt-1">
              <MapPin className="w-4 h-4 mr-1" />
              <span className="text-sm">1445 Spring Lake Rd, Waco, TX 76705</span>
            </div>
          </div>
        </div>

        {/* Primary Action Buttons */}
        <div className="px-4 space-y-3 mb-6">
          <a
            href="https://book.liveoaklake.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center gap-2 w-full py-3 bg-white text-black-900 rounded-lg border-2 border-black-900 hover:bg-black-800 transition-all shadow-sm"
          >
            <Calendar className="w-5 h-5" />
            <span className="font-medium">Book Your Stay</span>
          </a>
          <a
            href="https://www.liveoaklake.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center gap-2 w-full py-3 bg-white text-black-900 rounded-lg border-2 border-black-900 hover:bg-black-50 transition-all"
          >
            <ExternalLink className="w-5 h-5" />
            <span className="font-medium">Visit Main Website</span>
          </a>
        </div>

        {/* Awards Card */}
        <div className="mx-4 mb-6">
          <div className="bg-white p-4 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl border-2 border-black-900">
            <div className="flex items-center gap-2 mb-3">
              <Award className="w-5 h-5 text-yellow-500" />
              <h2 className="text-lg font-semibold text-black-900">Awards & Recognition</h2>
            </div>
            <div className="flex flex-wrap gap-2">
              <a 
                href="https://www.isaacjfrench.com/newsletter/blog-thestoryofliveoaklake"
                target="_blank"
                rel="noopener noreferrer"
                className="px-3 py-1 bg-black-50 text-black-900 rounded-full text-sm hover:bg-black-100 transition-all flex items-center gap-1"
              >
                <span>Experiential Hospitality</span>
                <ExternalLink className="w-3 h-3" />
              </a>
              <a 
                href="https://www.entrepreneur.com/business-news/this-25-year-olds-tiny-hotel-generates-500000-a-year/437440"
                target="_blank"
                rel="noopener noreferrer"
                className="px-3 py-1 bg-black-50 text-black-900 rounded-full text-sm hover:bg-black-100 transition-all flex items-center gap-1"
              >
                <span>Entrepreneur.com</span>
                <ExternalLink className="w-3 h-3" />
              </a>
            </div>
          </div>
        </div>

        {/* Amenities Card */}
        <div className="mx-4 mb-6">
          <div className="bg-white rounded-xl p-6 shadow-lg transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl border-2 border-black-900">
            <h2 className="text-lg font-semibold mb-4 text-black-900">Amenities</h2>
            <div className="flex flex-wrap justify-between gap-y-4">
              {amenities.map((amenity, idx) => (
                <div 
                  key={idx} 
                  className="flex flex-col items-center w-1/3 transform transition-all duration-300 hover:scale-110"
                >
                  <div className="p-2 bg-black-50 rounded-full text-black-900">
                    {amenity.icon}
                  </div>
                  <span className="text-xs mt-1">{amenity.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Guest Reviews Card */}
        <div className="mx-4 mb-6">
          <div className="bg-white rounded-xl p-6 shadow-lg transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl border-2 border-black-900">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold text-black-900">Guest Reviews</h2>
              <div className="flex items-center gap-1">
                {renderStars(5)}
              </div>
            </div>

            <div className="flex gap-3 mb-4">
              <a 
                href="https://www.google.com.ng/travel/search?ts=CAAaACoCCgA&qs=MihDaG9JbHFqTTM2eUQ4ZVBMQVJvTkwyY3ZNVEZ1YURZNE5UTmtkeEFC&utm_campaign=sharing&utm_medium=link_btn&utm_source=htls"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 bg-black-50 text-black-900 rounded-full text-sm hover:bg-black-100 transition-colors flex items-center gap-2"
              >
                Google Reviews
                <ExternalLink className="w-4 h-4" />
              </a>
              <a 
                href="https://www.yelp.com/biz/live-oak-lake-waco"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 bg-black-50 text-black-900 rounded-full text-sm hover:bg-black-100 transition-colors flex items-center gap-2"
              >
                Yelp Reviews
                <ExternalLink className="w-4 h-4" />
              </a>
            </div>

            <div className="relative bg-white rounded-lg p-6 border">
              <button
                onClick={prevReview}
                className="absolute left-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black-50 text-black-900 hover:bg-black-100 transition-colors z-10"
                aria-label="Previous review"
              >
                <ChevronLeft className="w-4 h-4" />
              </button>

              <div className="transition-all duration-300 ease-in-out px-8">
                <div className="flex items-center gap-1 mb-2">
                  {renderStars(reviews[currentReview].rating)}
                                    <span className="text-sm text-gray-600 ml-2">
                                      via {reviews[currentReview].source}
                                    </span>
                                  </div>
                                  <p className="text-gray-700 italic mb-3">"{reviews[currentReview].text}"</p>
                                  <div className="text-sm text-gray-600">
                                    <span className="font-medium">{reviews[currentReview].author}</span>
                                    <span className="mx-2">•</span>
                                    <span>{reviews[currentReview].date}</span>
                                  </div>
                                </div>

                                <button
                                  onClick={nextReview}
                                  className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black-50 text-black-900 hover:bg-black-100 transition-colors z-10"
                                  aria-label="Next review"
                                >
                                  <ChevronRight className="w-4 h-4" />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Popular Rooms Card */}
                          <div className="mx-4 mb-6">
                            <div className="bg-white rounded-xl p-6 shadow-lg transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl border-2 border-black-900">
                              <h2 className="text-lg font-semibold mb-4 text-black-900">Popular Cabins</h2>
                              <div className="relative">
                                <div className="overflow-hidden rounded-lg">
                                  <div className="relative">
                                    <img 
                                      src={popularRooms[currentRoom].image} 
                                      alt={popularRooms[currentRoom].name} 
                                      className="w-full h-48 object-cover"
                                    />
                                    <div className="p-3 bg-white">
                                      <div className="flex justify-between items-center mb-1">
                                        <h3 className="font-medium text-black-900">{popularRooms[currentRoom].name}</h3>
                                        <span className="text-sm font-semibold">{popularRooms[currentRoom].price}</span>
                                      </div>
                                      <p className="text-sm text-gray-600 mb-2">{popularRooms[currentRoom].description}</p>
                                      <div className="grid grid-cols-2 gap-16">
                                        <button className="flex items-center justify-center gap-1 py-2 bg-black-50 text-black-900 rounded-md text-me hover:bg-black-100 transition-colors">
                                          <Camera className="w-4 h-4" />
                                          <span>Virtual Tour</span>
                                        </button>
                                        <a
                                          href="https://book.liveoaklake.com"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="flex items-center justify-center gap-1 py-2 bg-black-900 text-gray rounded-md text-me hover:bg-black-800 transition-colors"
                                        >
                                          <Calendar className="w-4 h-4" />
                                          <span>Book Now</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  onClick={prevRoom}
                                  className="absolute left-2 top-24 -translate-y-1/2 p-2 rounded-full bg-white/80 text-black-900 hover:bg-white transition-colors"
                                >
                                  <ChevronLeft className="w-6 h-6" />
                                </button>
                                <button
                                  onClick={nextRoom}
                                  className="absolute right-2 top-24 -translate-y-1/2 p-2 rounded-full bg-white/80 text-black-900 hover:bg-white transition-colors"
                                >
                                  <ChevronRight className="w-6 h-6" />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Location Card */}
                          <div className="mx-4 mb-6">
                            <div className="bg-white rounded-xl p-6 shadow-lg transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl border-2 border-black-900">
                              <h2 className="text-lg font-semibold mb-4 text-black-900">Location</h2>
                              <div className="rounded-lg overflow-hidden aspect-video">
                                <iframe 
                                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13587.42290085653!2d-97.1197485!3d31.6378004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864f9da487c0ce23%3A0xcbc7c41acbf31416!2sLive%20Oak%20Lake!5e0!3m2!1sen!2sus!4v1730859886618!5m2!1sen!2sus"  
                                  className="w-full h-full"
                                  style={{ border: 0 }}
                                  allowFullScreen=""
                                  loading="lazy"
                                  referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                              </div>
                            </div>
                          </div>

                          {/* Experience the Area Card */}
                          <div className="mx-4 mb-6">
                            <div className="bg-white rounded-xl p-6 shadow-lg transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl border-2 border-black-900">
                              <h2 className="text-lg font-semibold mb-4 text-black-900">Experience the Area</h2>
                              <div className="relative">
                                <div className="overflow-hidden rounded-lg">
                                  <div className="bg-white border rounded-lg">
                                    <img 
                                      src={attractions[currentAttraction].image} 
                                      alt={attractions[currentAttraction].name} 
                                      className="w-full h-48 object-cover rounded-t-lg"
                                    />
                                    <div className="p-4">
                                      <h3 className="font-medium text-black-900 mb-1">{attractions[currentAttraction].name}</h3>
                                      <span className="text-sm text-black-700 block mb-2">{attractions[currentAttraction].type}</span>
                                      <p className="text-sm text-gray-600">{attractions[currentAttraction].description}</p>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  onClick={prevAttraction}
                                  className="absolute left-2 top-24 -translate-y-1/2 p-2 rounded-full bg-white/80 text-black-900 hover:bg-white transition-colors"
                                >
                                  <ChevronLeft className="w-6 h-6" />
                                </button>
                                <button
                                  onClick={nextAttraction}
                                  className="absolute right-2 top-24 -translate-y-1/2 p-2 rounded-full bg-white/80 text-black-900 hover:bg-white transition-colors"
                                >
                                  <ChevronRight className="w-6 h-6" />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* FAQ Section */}
                          <div className="mx-4 mb-6">
                            <div className="bg-white rounded-xl p-6 shadow-lg transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl border-2 border-black-900">
                              <h2 className="text-lg font-semibold mb-4 text-black-900">Frequently Asked Questions</h2>
                              <div className="space-y-2">
                                {faqs.map((faq, index) => (
                                  <div key={index} className="border rounded-lg overflow-hidden">
                                    <button
                                      onClick={() => setOpenFaq(openFaq === index ? null : index)}
                                      className="w-full px-4 py-3 text-left bg-gray-50 hover:bg-gray-100 flex justify-between items-center transition-all duration-300"
                                    >
                                      <span className="font-medium text-black-900">{faq.question}</span>
                                      {openFaq === index ? (
                                        <Minus className="w-5 h-5 text-gray-600" />
                                      ) : (
                                        <Plus className="w-5 h-5 text-gray-600" />
                                      )}
                                    </button>
                                    <div
                                      className={`px-4 transition-all duration-300 overflow-hidden ${
                                        openFaq === index ? 'py-3 max-h-40' : 'max-h-0'
                                      }`}
                                    >
                                      <p className="text-gray-700">{faq.answer}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>

                          {/* Gallery Card */}
                          <div className="mx-4 mb-6">
                            <div className="bg-white rounded-xl p-6 shadow-lg transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl border-2 border-black-900">
                              <h2 className="text-lg font-semibold mb-4 text-black-900">Gallery</h2>
                              <div className="grid grid-cols-3 gap-2">
                                {images.gallery.map((image, idx) => (
                                  <button
                                    key={idx}
                                    onClick={() => {
                                      setSelectedGalleryImage(idx);
                                      setShowGallery(true);
                                    }}
                                    className="relative aspect-square overflow-hidden rounded-lg transform transition-all duration-300 hover:scale-105 hover:shadow-lg"
                                  >
                                    <img src={image} alt={`Gallery ${idx + 1}`} className="w-full h-full object-cover" />
                                  </button>
                                ))}
                              </div>
                            </div>
                          </div>
        
                          {/* Social Links Card */}
                          <div className="mx-4 mb-6">
                            <div className="bg-white rounded-xl p-6 shadow-lg transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl border-2 border-black-900">
                              <h2 className="text-lg font-semibold mb-4 text-black-900">Socials</h2>
                              <div className="flex justify-center gap-8">
                                {socialLinks.map((social, idx) => (
                                  <a
                                    key={idx}
                                    href={social.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex flex-col items-center gap-2 transform transition-all duration-300 hover:scale-110"
                                  >
                                    <div className="p-3 bg-black-50 rounded-full text-black-900 hover:bg-black-100">
                                      {social.icon}
                                    </div>
                                    <span className="text-sm text-gray-600">{social.label}</span>
                                  </a>
                                ))}
                              </div>
                            </div>
                          </div>

                          {/* Expa Card */}
                          <div className="mx-4 mb-20">
                            <div className="bg-white rounded-xl p-6 shadow-lg transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl border-2 border-black-900">
                              <div className="text-center">
                                <span className="text-gray-700">
                                  Join Live Oak Lake on{' '}
                                  <a
                                    href="https://tryexpa.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-black-900 font-semibold hover:underline"
                                  >
                                    Expa
                                  </a>
                                  {' '}today.
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* Floating Action Buttons */}
                          <div className="fixed bottom-6 left-0 right-0 px-4 z-50">
                            <div className="max-w-sm mx-auto flex justify-between gap-4">
                              <a 
                                href="https://www.liveoaklake.com/" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="flex-1 flex items-center justify-center gap-2 px-4 py-3 bg-white text-black-900 rounded-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl border-2 border-black-900 max-w-[180px]"
                              >
                                <ExternalLink className="w-5 h-5" />
                                <span>View Main Site</span>
                              </a>
                              <a
                                href="https://book.liveoaklake.com/"
                                target="_blank"
                                rel="noopener noreferrer" 
                                className="flex-1 flex items-center justify-center gap-2 px-4 py-3 bg-white text-black-900 rounded-full shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl border-2 border-black-900 max-w-[160px]"
                              >
                                <Calendar className="w-5 h-5" />
                                <span>Book Now</span>
                              </a>
                            </div>
                          </div>

                          {/* Gallery Modal */}
                          {showGallery && selectedGalleryImage !== null && (
                            <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 animate-fade-in">
                              <button
                                onClick={() => {
                                  setShowGallery(false);
                                  setSelectedGalleryImage(null);
                                }}
                                className="absolute top-4 right-4 text-white hover:text-gray-300 transform transition-all duration-300 hover:scale-110"
                              >
                                <X className="w-8 h-8" />
                              </button>

                              <button
                                onClick={() => navigateGallery('prev')}
                                className="absolute left-4 top-1/2 -translate-y-1/2 p-3 rounded-full bg-white/10 text-white hover:bg-white/20 transform transition-all duration-300 hover:scale-110"
                              >
                                <ChevronLeft className="w-8 h-8" />
                              </button>

                              <img
                                src={images.gallery[selectedGalleryImage]}
                                alt={`Gallery ${selectedGalleryImage + 1}`}
                                className="max-w-full max-h-[90vh] object-contain animate-scale-in px-16"
                              />

                              <button
                                onClick={() => navigateGallery('next')}
                                className="absolute right-4 top-1/2 -translate-y-1/2 p-3 rounded-full bg-white/10 text-white hover:bg-white/20 transform transition-all duration-300 hover:scale-110"
                              >
                                <ChevronRight className="w-8 h-8" />
                              </button>

                              <div className="absolute bottom-4 left-1/2 -translate-x-1/2 text-white bg-black/50 px-4 py-2 rounded-full">
                                {selectedGalleryImage + 1} / {images.gallery.length}
                              </div>
                            </div>
                          )}

                    <style jsx global>{`
                            @keyframes fade-in {
                              from { opacity: 0; }
                              to { opacity: 1; } 
                            }
            
                            @keyframes scale-in {
                              from { 
                                transform: scale(0.9); 
                                opacity: 0; 
                              }
                              to { 
                                transform: scale(1); 
                                opacity: 1; 
                              }
                            }
            
                            .animate-fade-in {
                              animation: fade-in 0.3s ease-out;
                            }
            
                            .animate-scale-in {
                              animation: scale-in 0.3s ease-out;
                            }
            
                            /* Custom scrollbar styling */
                            ::-webkit-scrollbar {
                              width: 6px;
                            }
            
                            ::-webkit-scrollbar-track {
                              background: #f1f1f1;
                              border-radius: 3px;
                            }
            
                            ::-webkit-scrollbar-thumb {
                              background: #888;
                              border-radius: 3px;
                            }
            
                            ::-webkit-scrollbar-thumb:hover {
                              background: #555;
                            }
            
                            /* Smooth transitions */
                            * {
                              transition: all 0.3s ease-in-out;
                            }
            
                            /* Desktop optimization */
                            @media (min-width: 768px) {
                              body {
                                background-color: #ffffff;
                              }
            
                              .max-w-md {
                                position: relative;
                                margin: 0 auto;
                              }
                            }
                          `}</style>
                        </div>
                      </div>
                    );
                  };

                  export default HotelLinkInBio;